/// Flex container shortcut.
///
/// @param {string} $direction
///   flex-direction value
/// @param {string} $justify
///   justify-content value
/// @param {string} $align
///   align-items value
@mixin flex_container($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}
