@use "../../css/colors" as colors;
@use "../../css/mixins" as mixins;
@use "../../css/variables" as variables;

.container {
  @include mixins.flex_container(column, flex-start, flex-start);
  width: 100%;
  padding: 1rem 0rem 1.9rem 1.6rem;

  .titleContainer {
    @include mixins.flex_container(row, flex-start, flex-start);
    width: 100%;

    p {
      white-space: nowrap;
    }

    div {
      margin-left: 0.5rem;
      height: 1rem;
      width: 100%;
      background-image: linear-gradient(to right, colors.$dotted-line-colors);
      background-position: bottom;
      background-size: 0.375rem 0.063rem;
      background-repeat: repeat-x;
    }
  }

  .text,
  .pressContainer,
  .listContainer {
    padding: 1rem 1rem 2rem calc(0.456rem * 3 + 0.3rem);
    max-width: calc(50.75rem - calc(0.456rem * 6) - 1.6rem);
  }

  .pressContainer {
    @include mixins.flex_container(row, space-between, flex-start);
    width: 40rem;

    .press {
      @include mixins.flex_container(row, flex-start, flex-start);

      p {
        margin-right: 0.3rem;
      }
    }
  }

  .listContainer {
    a,
    div {
      @include mixins.flex_container(row, flex-start, flex-start);

      p {
        margin-right: 0.3rem;
      }
    }
  }
}
