@use "../../css/variables" as variables;
@use "../../css/mixins" as mixins;

.container {
  @include mixins.flex_container(column, center, center);
  width: 100%;
  padding-left: variables.$web-padding-bigger-desktop;
}

/*NORMAL DESKTOP*/
@media (max-width: 1920px) {
  .container {
    padding-left: variables.$web-padding-normal-desktop;
  }
}

/*SMALL LAPTOP*/
@media (max-width: 1280px) {
  .container {
    padding: 0 variables.$web-padding-normal-desktop;
  }
}
