@use "../../css/colors" as colors;
@use "../../css/mixins" as mixins;
@use "../../css/variables" as variables;

.container {
  @include mixins.flex_container(row, space-between, center);
  width: 100%;
  height: 51rem;
  background-image: linear-gradient(to right, colors.$dotted-line-colors);
  background-position: top;
  background-size: 0.375rem 0.063rem;
  background-repeat: repeat-x;

  p {
    text-transform: uppercase;
  }

  .compactNftView {
    display: none;
  }

  .artPiece {
    @include mixins.flex_container(column, center, center);
    background-color: colors.$color-transparent;
    position: relative;
    width: 50%;
    height: 100%;

    p {
      position: absolute;
      transform: translate(0%, 0%);
      left: 0%;
      top: 0%;
      margin-left: 0.9rem;
      margin-top: 1rem;
    }

    img {
      max-height: 38rem;
      max-width: 50rem;
    }
  }

  .verticalLine {
    background-color: colors.$color-transparent;
    width: 0.376rem;
    height: 100%;
    background-image: linear-gradient(colors.$dotted-line-colors);
    background-position: 0% 0%;
    background-size: 0.063rem 0.375rem;
    background-repeat: repeat-y;
  }

  .artInfo {
    @include mixins.flex_container(column, space-between, center);
    background-color: colors.$color-transparent;
    height: 100%;
    width: 50%;

    div {
      @include mixins.flex_container(row, space-between, center);
      width: 100%;
      margin-top: 1rem;
      padding: 0 1rem;
    }

    .description {
      width: 100%;
      text-align: center;

      span {
        display: inline-flex;
        align-items: center;

        img {
          width: 2rem;
        }
      }
    }

    a {
      @include mixins.flex_container(row, center, center);
      margin-bottom: 1rem;

      img {
        margin-top: -0.2rem;
        margin-left: 1rem;
        width: 0.8rem;
      }
    }
  }
}

/*NORMAL DESKTOP*/
@media (max-width: 1920px) {
  .container {
    .artPiece {
      img {
        max-height: 38rem;
        max-width: 45rem;
      }
    }

    .artInfo {
      a {
        img {
          width: 0.75rem;
        }
      }
    }
  }
}

/*BIG LAPTOP*/
@media (max-width: 1680px) {
  .container {
    height: 40rem;

    .artPiece {
      img {
        max-height: 35rem;
        max-width: 40rem;
      }
    }

    .artInfo {
      a {
        img {
          width: 0.688rem;
        }
      }
    }
  }
}

/*SMALL LAPTOP*/
@media (max-width: 1440px) {
  .container {
    height: 36rem;

    .artPiece {
      img {
        max-height: 30rem;
        max-width: 35rem;
      }
    }
  }
}

@media (max-width: 1280px) {
  .container {
    height: 30rem;

    .artPiece {
      img {
        max-height: 25rem;
        max-width: 25rem;
      }
    }
  }
}

@media (max-width: 1024px) {
  .container {
    height: auto;

    .compactNftView {
      @include mixins.flex_container(column, flex-start, center);
      margin: 0.5rem 0;
      background-color: colors.$color-transparent;
      width: 100%;

      > img,
      > div:nth-child(2) {
        width: 100% !important;
      }

      .topInfo {
        @include mixins.flex_container(row, space-between, center);
        width: 100%;
        margin: 0.5rem 0;
      }

      .description {
        @include mixins.flex_container(row, space-between, flex-start);
        width: 100%;
        margin: 0.5rem 0;

        p {
          text-align: start;

          span {
            display: inline-flex;
            align-items: center;

            img {
              width: 2rem;
            }
          }
        }

        a {
          @include mixins.flex_container(row, center, center);

          img {
            margin-top: -0.1rem;
            margin-left: 0.5rem;
            width: 0.6rem;
          }
        }
      }
    }

    .artPiece,
    .verticalLine,
    .artInfo {
      display: none;
    }
  }
}
