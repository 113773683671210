@use "../../css/colors" as colors;
@use "../../css/mixins" as mixins;
@use "../../css/variables" as variables;

.container {
  @include mixins.flex_container(row, center, center);
  cursor: default;
  background-color: colors.$color-transparent;
  position: fixed;
  bottom: 0;
  width: 100vw;
  text-align: center;
  padding: 1rem;
  margin: 4rem auto 2rem;
  line-height: normal;
  clear: both;
  text-align: center;
  z-index: 9;

  .show {
    opacity: 1;
  }

  p {
    transition: opacity 0.2s ease-out;
    opacity: 0;
    width: 10rem;
    cursor: pointer;
    background-color: colors.$color-transparent;
    border: none;
  }
}
