@use "../../css/mixins" as mixins;

.hidedSection {
  width: 100%;
}

.option {
  @include mixins.flex_container(row, flex-start, flex-start);
  z-index: 10;
  border: none;

  p {
    margin-right: 0.25rem;
  }
}

p.option {
  cursor: default;
}
