@use "../../css/colors" as colors;
@use "../../css/mixins" as mixins;
@use "../../css/variables" as variables;

.slideDown {
  width: 100%;
}

.container {
  @include mixins.flex_container(column, center, center);
  width: 100%;
  margin-top: -0.15rem;
  padding: 2.5rem 0;
  padding-bottom: 2.35rem;
  background-image: linear-gradient(to right, colors.$dotted-line-colors);
  background-position: bottom;
  background-size: 0.375rem 0.063rem;
  background-repeat: repeat-x;

  p {
    text-align: center;
    max-width: 62.5rem;
    margin: 0 auto;
    padding: 0 1rem;
  }
}

/*NORMAL DESKTOP*/
@media (max-width: 1920px) {
  .container {
    padding: 2.35rem 0;
    padding-bottom: 2.15rem;
  }
}

/*BIG LAPTOP*/
@media (max-width: 1680px) {
  .container {
    padding: 2.2rem 0;
    padding-bottom: 2rem;
  }
}

/*SMALL LAPTOP*/
@media (max-width: 1440px) {
  .container {
    padding: 2.05rem 0;
    padding-bottom: 1.85rem;
  }
}
