@use "./css/colors" as colors;
@use "./css/fonts" as fonts;
@use "./css/variables" as variables;

@font-face {
  font-family: "SimplonBPMono";
  src: local("SimplonBPMono-Light"),
    url("assets/fonts/SimplonBPMono-Light.woff2") format("woff2");
  font-weight: regular;
  font-style: normal;
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  background-color: colors.$color-transparent !important;
  width: variables.$web-padding-bigger-desktop;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: fonts.$simplon-bp-mono;
  background-color: colors.$color-background;
  line-height: 1.4;
  font-variant-ligatures: discretionary-ligatures;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-moz-selection {
  color: colors.$color-white;
  background: colors.$color-black;
}

::selection {
  color: colors.$color-white;
  background: colors.$color-black;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none !important;
}

a,
p {
  color: colors.$color-font;
}

body,
html {
  width: 100%;
  height: 100%;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

/*DEFAULT*/
a,
p,
button {
  font-size: variables.$font-size-small-laptop;
}

/*BIGGER DESKTOP*/
@media (min-width: 1921px) {
  a,
  p,
  button {
    font-size: variables.$font-size-bigger-desktop;
  }
}

/*NORMAL DESKTOP*/
@media (min-width: 1681px) and (max-width: 1920px) {
  a,
  p,
  button {
    font-size: variables.$font-size-normal-desktop;
  }
}

/*BIG LAPTOP*/
@media (min-width: 1440px) and (max-width: 1680px) {
  a,
  p,
  button {
    font-size: variables.$font-size-big-laptop;
  }
}
