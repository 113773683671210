@use "../../css/colors" as colors;
@use "../../css/mixins" as mixins;
@use "../../css/variables" as variables;

.container {
  @include mixins.flex_container(column, flex-start, flex-start);
  padding: 1rem 0rem 1.9rem 1.6rem;

  a {
    @include mixins.flex_container(row, flex-start, flex-start);

    p {
      margin-right: 0.3rem;
    }
  }
}
