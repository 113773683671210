@use "../../css/colors" as colors;
@use "../../css/mixins" as mixins;
@use "../../css/variables" as variables;

.container {
  @include mixins.flex_container(column, center, center);
  width: 100%;
  padding-left: 0.03rem;

  .topLinks {
    @include mixins.flex_container(row, space-between, center);
    width: 100%;
    padding-top: 0.9rem;
    padding-bottom: 0.5rem;

    .socialMedia {
      margin-right: 0.05rem;
    }
  }

  .menu {
    @include mixins.flex_container(column, flex-start, flex-start);
    width: 100%;
    padding-left: 0.95rem;

    .hidedSection {
      width: 100%;
    }

    .option {
      @include mixins.flex_container(row, flex-start, flex-start);
      z-index: 10;
      border: none;

      p {
        margin-right: 0.25rem;
      }
    }

    p.option {
      cursor: default;
    }
  }
}

/*NORMAL DESKTOP*/
@media (max-width: 1920px) {
  .container {
    padding-left: 0.01rem;

    .topLinks {
      padding-bottom: 0.45rem;
    }

    .menu {
      padding-left: 0.85rem;
    }
  }
}

/*BIG LAPTOP*/
@media (max-width: 1680px) {
  .container {
    padding-left: 0;
    margin-left: -0.12rem;

    .topLinks {
      padding-top: 0.8rem;
      padding-bottom: 0.45rem;

      .socialMedia {
        margin-right: -0.1rem;
      }
    }

    .menu {
      padding-left: 0.85rem;
    }
  }
}

/*SMALL LAPTOP*/
@media (max-width: 1440px) {
  .container {
    margin-left: -0.24rem;

    .topLinks {
      padding-bottom: 0.3rem;

      .socialMedia {
        margin-right: -0.15rem;
      }
    }

    .menu {
      padding-left: 0.75rem;
    }
  }
}

/*MEDIUM MOBILE*/
@media (max-width: 600px) {
  .topLinks {
    .socialMedia {
      display: none;
    }
  }
}
