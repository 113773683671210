@use "../../css/colors" as colors;
@use "../../css/mixins" as mixins;
@use "../../css/variables" as variables;

.container {
  @include mixins.flex_container(column, flex-start, center);
  padding: 0 1rem;
  width: 100%;

  .line {
    width: 100%;
    height: 0.2rem;
    background-image: linear-gradient(to right, colors.$dotted-line-colors);
    background-position: top;
    background-size: 0.375rem 0.063rem;
    background-repeat: repeat-x;
  }

  .content {
    @include mixins.flex_container(column, space-between, center);
    padding: 1rem 0;
    width: 100%;
    height: 13rem;
    position: relative;
    z-index: 100;

    > p {
      text-align: center;
      width: 100%;
    }

    .topInfo {
      @include mixins.flex_container(row, space-between, flex-start);
      width: 100%;

      .contactData,
      .socialMedia,
      .backToTop {
        width: 50%;
      }

      .contactData {
        @include mixins.flex_container(column, flex-start, flex-start);

        a {
          text-align: start;
        }
      }

      .backToTop {
        text-align: center;

        &::after {
          content: "[ BACK TO TOP ↑ ]";
        }
      }

      .socialMedia {
        @include mixins.flex_container(row, flex-end, flex-start);

        a {
          text-align: end;
        }

        p {
          margin: 0 0.5rem;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .container {
    .content {
      .topInfo {
        .backToTop {
          &::after {
            content: "[ TOP ↑ ]";
          }
        }
        .socialMedia {
          @include mixins.flex_container(column, flex-start, flex-end);

          p {
            display: none;
          }
        }
      }
    }
  }
}
